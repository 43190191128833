import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import Base from "./pages/base";
import Welcome from "./pages/welcome";
import Password from "./pages/password";
import Terms from "./pages/terms";
import Policy from "./pages/policy";
import LGPD from "./pages/lgpd";
import Error from "./pages/error";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Base />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Welcome />,
      },
      {
        path: "/nova-senha",
        element: <Password />,
      },
      {
        path: "/termos-de-uso",
        element: <Terms />,
      },
      {
        path: "/politica-de-privacidade",
        element: <Policy />,
      },
      {
        path: "/lgpd",
        element: <LGPD />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
